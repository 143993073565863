import Glide from "@glidejs/glide";

export default class Sliders {
    setupSearchPageSliders() {
        this.setupMapCarousel();
        this.setupCabinShipCarousel();
        this.setupSearchDateSlider();
        this.setupAdvantagesSliders();
        this.setupHomeSliders()
    };

    setupProductPageSliders() {
        const sliders = document.querySelectorAll('.glide__product--ship');
        const cabinInteriorSliders = document.querySelector('.glide--cabinsPageProduct_I');
        const cabinExteriorSliders = document.querySelector('.glide--cabinsPageProduct_E');
        const cabinBalconySliders = document.querySelector('.glide--cabinsPageProduct_B');
        const cabinSuiteSliders = document.querySelector('.glide--cabinsPageProduct_S');

        let cabinSliders = [];

        if (cabinInteriorSliders) {
            cabinSliders.push(new Glide(cabinInteriorSliders, {
                type: 'slider',
                perView: 1,
                swipeThreshold: 40,
                peek: {
                    before: 0,
                    after: 50
                }
            }));
        }

        if (cabinExteriorSliders) {
            cabinSliders.push(new Glide(cabinExteriorSliders, {
                type: 'slider',
                perView: 1,
                swipeThreshold: 40,
                peek: {
                    before: 0,
                    after: 50
                }
            }));
        }

        if (cabinBalconySliders) {
            cabinSliders.push(new Glide(cabinBalconySliders, {
                type: 'slider',
                perView: 1,
                swipeThreshold: 40,
                peek: {
                    before: 0,
                    after: 50
                }
            }));
        }

        if (cabinSuiteSliders) {
            cabinSliders.push(new Glide(cabinSuiteSliders, {
                type: 'slider',
                perView: 1,
                swipeThreshold: 40,
                peek: {
                    before: 0,
                    after: 50
                }
            }));
        }

        cabinSliders.forEach((elem) => {
            elem.mount();
        });

        window.cabinSlidersProductPage = cabinSliders;

        for (let i = 0; i < sliders.length; i++) {
            let slider = new Glide(sliders[i], {
                type: 'slider',
                perView: 1,
                swipeThreshold: 40
            });

            slider.mount();
        }
    };

    updateAllSliders() {
        if(typeof cabinSlidersProductPage !== 'undefined') {
            window.cabinSlidersProductPage.forEach((elem) => elem.update());
        }
    }

    setupMapCarousel() {
        const sliders = document.querySelectorAll('.glide__search');

        for (let i = 0; i < sliders.length; i++) {
            let slider = new Glide(sliders[i], {
                type: 'slider',
                perView: 1,
                swipeThreshold: 40
            });

            slider.mount();
        }
    }

    setupOrderCarousel() {
        const slider = document.querySelector('.glide--order');

        new Glide(slider, {
            type: 'slider',
            perView: 1,
            swipeThreshold: 40
        }).mount();
    }

    setupSearchDateSlider() {
        const sliders = document.querySelectorAll('.slider--search-date');
        let selectedDate = new URLSearchParams(window.location.search).get('date[0]');

        const params = new URLSearchParams(window.location.search);

        if (!selectedDate) {
            selectedDate = new URLSearchParams(window.location.search).get('date');
        }

        let indexOfSelectedDateInSlider = 0;

        for (let i = 0; i < sliders.length; i++) {
            if (selectedDate) {
                indexOfSelectedDateInSlider = this.getSelectedDateInSlider(sliders[i], selectedDate);
            }

            let slider = new Glide(sliders[i], {
                type: 'slider',
                perView: 10,
                dragThreshold: false,
                rewind: true,
                startAt: indexOfSelectedDateInSlider,
                focusAt: 'center',
                breakpoints: {
                    350: {
                        perView: 3,
                        swipeThreshold: 1
                    },
                    // For breakpoint < 767
                    767: {
                        perView: 4,
                        swipeThreshold: 1
                    },
                    // For breakpoint < 1023
                    1023: {
                        perView: 5,
                        swipeThreshold: 1
                    }
                }
            });

            slider.mount();
        }
    };

    getSelectedDateInSlider(sliders, selectedDate) {
        let result = undefined;

        result = [...sliders.firstElementChild.firstElementChild.children].findIndex((value) => {
            let dateOfCurrentItem = new Date(value.getAttribute('date'));
            return (dateOfCurrentItem.getMonth() === new Date(selectedDate).getMonth()
                    && dateOfCurrentItem.getFullYear() === new Date(selectedDate).getFullYear()
            );
        });
        return result;
    }

    setupAdvantagesSliders() {
        const sliders = document.querySelectorAll('.slider--advantages');

        for (let i = 0; i < sliders.length; i++) {
            let slider = new Glide(sliders[i], {
                type: 'slider',
                perView: 3,
                gap: 2,
                peek: {before: 0, after: 50},
                breakpoints: {
                    767: {perView: 1},
                    1023: {perView: 2}
                }

            });

            slider.mount();
        }
    };

    setupCruisePageSliders() {
        this.setupCruiseCarousel();
        this.setupCruiseCabinCarousel();
    }

    setupCruiseCarousel() {
        if (document.getElementById('cruise_carousel')) {
            this.cruiseCarousel = new Glide('#cruise_carousel', {
                swipeThreshold: false,
                dragThreshold: false,
                animationDuration: 0,
                rewindDuration: 0
            }).mount();
        }

        if (document.getElementById('ship_carousel_thubnails')) {
            this.cruiseCarouselThumbnail = new Glide('#ship_carousel_thubnails', {
                type: 'slider',
                gap: 17,
                animationDuration: 0,
                rewindDuration: 0
            }).mount();
        }
    }

    nextCruiseCarousel() {
        this.cruiseCarousel.go('>');
    }

    prevCruiseCarousel() {
        this.cruiseCarousel.go('<');
    }

    setupCabinShipCarousel() {
        this.cabinShip = [];

        for (const categoryName of ['I', 'E', 'B', 'S']) {
            if (document.querySelector('.cabins_' + categoryName + '_carousel--result') !== null) {
                this.cabinShip['.cabins_' + categoryName + '_carousel--result'] = new Glide('.cabins_' + categoryName + '_carousel--result', {
                    perView: 3,
                    gap: 25,
                    peek: {
                        before: 0,
                        after: 150
                    },
                    rewind: false,
                    perTouch: false,
                    bound: true,
                    breakpoints: {
                        767: {perView: 1, peek: 0},
                        1023: {perView: 2, peek: 0}
                    }
                }).mount();
            }
        }
    }

    updateAllCabinShipCarousel() {
        for (const cabinCategoryClass in this.cabinShip) {
            this.cabinShip[cabinCategoryClass].update();
        }
    }

    setupCruiseCabinCarousel() {
        this.cruiseCabins = [];

        for (const categoryName of ['I', 'E', 'B', 'S']) {
            if (document.querySelector('.cabins_' + categoryName + '_carousel') !== null) {
                this.cruiseCabins['.cabins_' + categoryName + '_carousel'] = new Glide('.cabins_' + categoryName + '_carousel', {
                    perView: 3,
                    gap: 25,
                    peek: {
                        before: 0,
                        after: 150
                    },
                    rewind: false,
                    perTouch: false,
                    bound: true,
                    breakpoints: {
                        767: {perView: 1, peek: 0},
                        1023: {perView: 2, peek: 0}
                    }
                }).mount();
            }
        }
    }

    setupHomeSliders() {
        this.setupMainSlider();
        this.setupDiscoverSliders();
        this.setupReviewSliders();
        this.setupShipOwnerSlider();
        this.setupBlogSlider();
    }

    /**
     * Sert à initialiser le slider qui est dans la page indépendante avis qui affiche tous les avis
     */
    setupReviewPageSlider() {
        this.setupReviewSliders();
    }

    setupMainSlider() {
        if (document.querySelector('.glide--slider') !== null) {
            new Glide('.glide--slider', {
                autoplay: 3000,
                hoverpause: true
            }).mount();
        }
    }

    setupDiscoverSliders() {
        let countDiscoverSliders = document.querySelectorAll('.tab').length;
        this.discoverSliders = [];

        for (let i = 0; i < countDiscoverSliders; i++) {
            if (document.getElementsByClassName(`carousel--discover-${i}`).length === 1) {
                this.discoverSliders[`.carousel--discover-${i}`] = new Glide(`.carousel--discover-${i}`, {
                    type: 'slider',
                    perView: 4,
                    startAt: 0,
                    breakpoints: {
                        767: {
                            perView: 1,
                            peek: {
                                before: 0,
                                after: 50
                            }
                        },
                        1023: {perView: 2}
                    },
                    swipeThreshold: 40
                }).mount();
            }
        }
    }

    /**
     * gére le slider qui est sur la page d'accueil avec trois avis
     * gére le slider de la page indépendante qui affiche tous les avis
     */
    setupReviewSliders() {
        let countReviewSliders = document.querySelectorAll('.tab-review').length;
        this.reviewSliders = [];
        for (let i = 0; i < countReviewSliders; i++) {
            if (document.getElementsByClassName(`carousel--review-${i}`).length === 1) {
                this.reviewSliders[`.carousel--review-${i}`] = new Glide(`.carousel--review-${i}`, {
                    type: 'slider',
                    perView: 2,
                    startAt: 0,
                    swipeThreshold: 40,
                    breakpoints: {
                        767: {
                            perView: 1,
                            peek: {
                                before: 0,
                                after: 50
                            }
                        },
                    }
                }).mount();
            }
        }
    }


    setupShipOwnerSlider() {
        if (document.getElementsByClassName('carousel--shipowner').length === 1) {
            new Glide('.carousel--shipowner', {
                type: 'carousel',
                perView: 5,
                gap: 5,
                peek: {
                    before: 0,
                    after: 100
                },
                breakpoints: {
                    767: {
                        perView: 1,
                        peek: {
                            before: 0,
                            after: 130
                        },
                    },
                    819: {
                        perView: 2,
                        peek: {
                            before: 0,
                            after: 130
                        },
                    },
                    820: {
                        perView: 3
                    }
                }
            }).mount();
        }
    }

    setupBlogSlider() {
        if (document.getElementsByClassName('carousel--blog').length === 1) {
            new Glide('.carousel--blog', {
                type: 'carousel',
                perView: 2,
                startAt: 0,
                swipeThreshold: 40,
                breakpoints: {
                    767: {
                        perView: 1,
                        peek: {
                            before: 0,
                            after: 50
                        }
                    }
                }
            }).mount();
        }
    }

    setupCompaniesPageSlider() {
        this.cruiseCarouselThumbnail = new Glide('.carousel--companies', {
            type: 'carousel',
            gap: 17,
            perView: 4,
            peek: {
                before: 0,
                after: 75
            },
            breakpoints: {
                767: {
                    perView: 1,
                },
                1023: {perView: 3}
            }
        }).mount();
    }

    refreshSlider(selector) {
        this.discoverSliders[selector].update();
        this.reviewSliders[selector].update();
    }

    setCruisePageCarouselItem(itemIndex) {
        this.cruiseCarousel.update({
            startAt: itemIndex
        });
    }

    setCruisePageCarouselItemInThumbnails(itemIndex) {
        this.cruiseCarouselThumbnail.update({
            startAt: Math.floor(itemIndex / 5)
        });
    }
}
