/**
 * Objet javascript qui gére la page de recherche
 * @type {{}|{}}
 */
export let scSearchPageManager = scSearchPageManager || {};


/**
 * ouvre une url dans un nouvel onglet
 * @param href
 */
scSearchPageManager.openInNewTab = (href) => {
    let redirectWindow = window.open(href, '_blank');
    redirectWindow.location;
};





