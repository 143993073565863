import 'alpinejs';
import Sliders from './Slider/Sliders';
import intlTelInput from 'intl-tel-input';
import intlTelInputFR from './intl-tel-input/fr_FR.json';
import axios from 'axios';
import BookingValidator from './Validator/BookingValidator';
import {addBackToTop} from 'vanilla-back-to-top';
import {scSearchPageManager} from "./searchPage";
import {scSearchFilterManager} from "./searchFilter";

window.sliders = new Sliders();
window.sliders.cabins = [];

window.Components = {
    customSelect(options) {
        return {
            init() {
                this.optionCount = this.$refs.listbox.children.length;
                this.$watch('selected', () => {
                    if (!this.open) {
                        return;
                    }

                    if (this.selected === null) {
                        this.activeDescendant = '';
                        return;
                    }

                    this.activeDescendant = this.$refs.listbox.children[this.selected].id;
                });
            },
            activeDescendant: null,
            optionCount: null,
            open: false,
            selected: null,
            value: 0,
            choose(option) {
                this.value = option;
                this.open = false;
            },
            onButtonClick() {
                if (this.open) {
                    return;
                }
                this.selected = this.value;
                this.open = true;
                this.$nextTick(() => {
                    this.$refs.listbox.focus();
                    this.$refs.listbox.children[this.selected].scrollIntoView(
                        {block: 'nearest'});
                });
            },
            onOptionSelect() {
                if (this.selected !== null) {
                    this.value = this.selected;
                }
                this.open = false;
                this.$refs.button.focus();
            },
            onEscape() {
                this.open = false;
                this.$refs.button.focus();
            },
            onArrowUp() {
                this.selected = this.selected - 1 < 0
                    ? this.optionCount - 1
                    : this.selected - 1;
                this.$refs.listbox.children[this.selected].scrollIntoView(
                    {block: 'nearest'});
            },
            onArrowDown() {
                this.selected = this.selected + 1 > this.optionCount - 1
                    ? 1
                    : this.selected + 1;
                this.$refs.listbox.children[this.selected].scrollIntoView(
                    {block: 'nearest'});
            },
            ...options,
        };
    },
    customSelect2(options) {
        let modelName = options.modelName || 'selected';

        return {
            init() {
                this.optionCount = this.$refs.listbox.children.length;
                this.$watch('activeIndex', () => {
                    if (!this.open) {
                        return;
                    }

                    if (this.activeIndex === null) {
                        this.activeDescendant = '';
                        return;
                    }

                    this.activeDescendant = this.$refs.listbox.children[this.activeIndex].id;
                });
            },
            activeDescendant: null,
            optionCount: null,
            open: false,
            activeIndex: null,
            selectedIndex: 0,
            get active() {
                return this.items[this.activeIndex];
            },
            get [modelName]() {
                return this.items[this.selectedIndex];
            },
            choose(option) {
                this.selectedIndex = option;
                this.open = false;
            },
            onButtonClick() {
                if (this.open) {
                    return;
                }
                this.activeIndex = this.selectedIndex;
                this.open = true;
                this.$nextTick(() => {
                    this.$refs.listbox.focus();
                    this.$refs.listbox.children[this.activeIndex].scrollIntoView(
                        {block: 'nearest'});
                });
            },
            onOptionSelect() {
                if (this.activeIndex !== null) {
                    this.selectedIndex = this.activeIndex;
                }
                this.open = false;
                this.$refs.button.focus();
            },
            onEscape() {
                this.open = false;
                this.$refs.button.focus();
            },
            onArrowUp() {
                this.activeIndex = this.activeIndex - 1 < 0 ? this.optionCount -
                    1 : this.activeIndex - 1;
                this.$refs.listbox.children[this.activeIndex].scrollIntoView(
                    {block: 'nearest'});
            },
            onArrowDown() {
                this.activeIndex = this.activeIndex + 1 > this.optionCount - 1
                    ? 0
                    : this.activeIndex + 1;
                this.$refs.listbox.children[this.activeIndex].scrollIntoView(
                    {block: 'nearest'});
            },
            ...options,
        };
    },
};

window.Components.popoverGroup = function popoverGroup() {
    return {
        __type: 'popoverGroup',
        init() {
            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true);
                    return;
                }
                if (e.target instanceof Element &&
                    !this.$el.contains(e.target)) {
                    window.dispatchEvent(
                        new CustomEvent('close-popover-group', {
                            detail: this.$el,
                        }),
                    );
                }
            };
            window.addEventListener('focus', handler, true);
        },
    };
};

window.Components.popover = function popover({
    open = false,
    focus = false,
    sticky = false,
    lastPos = window.scrollY
} = {}) {
    const focusableSelector = [
        '[contentEditable=true]',
        '[tabindex]',
        'a[href]',
        'area[href]',
        'button:not([disabled])',
        'iframe',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
    ].map((selector) => `${selector}:not([tabindex='-1'])`).join(',');

    function focusFirst(container) {
        const focusableElements = Array.from(
            container.querySelectorAll(focusableSelector));

        function tryFocus(element) {
            if (element === undefined) {
                return;
            }

            element.focus({preventScroll: true});

            if (document.activeElement !== element) {
                tryFocus(
                    focusableElements[focusableElements.indexOf(element) + 1]);
            }
        }

        tryFocus(focusableElements[0]);
    }

    return {
        display: true,
        __type: 'popover',
        open,
        sticky,
        lastPos: window.scrollY + 0,
        init() {
            if (focus) {
                this.$watch('open', (open) => {
                    if (open) {
                        this.$nextTick(() => {
                            focusFirst(this.$refs.panel);
                        });
                    }
                });
            }

            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true);
                    return;
                }
                let ref = focus ? this.$refs.panel : this.$el;
                if (this.open && e.target instanceof Element &&
                    !ref.contains(e.target)) {
                    let node = this.$el;
                    while (node.parentNode) {
                        node = node.parentNode;
                        if (node.__x instanceof this.constructor) {
                            if (node.__x.$data.__type === 'popoverGroup') {
                                return;
                            }
                            if (node.__x.$data.__type === 'popover') {
                                break;
                            }
                        }
                    }
                    this.open = false;
                }
            };

            window.addEventListener('focus', handler, true);
        },
        onEscape() {
            this.open = false;
            if (this.restoreEl) {
                this.restoreEl.focus();
            }
        },
        onClosePopoverGroup(e) {
            if (e.detail.contains(this.$el)) {
                this.open = false;
            }
        },
        toggle(e) {
            this.open = !this.open;
            if (this.open) {
                this.restoreEl = e.currentTarget;
            }
            else if (this.restoreEl) {
                this.restoreEl.focus();
            }
        },
        scroll() {
            this.sticky = window.scrollY > this.$refs.navbar.offsetHeight && this.lastPos > window.scrollY;
            this.lastPos = window.scrollY;
        }
    };
};

window.Components.searchSelector = function searchSelector() {
    return {
        _type: 'searchSelector',
        selected: {},
        type: '',
        updated: false,
        init(data) {
            this.$nextTick(() => {
                for (let i = 0; i < data.length; i++) {
                    this.selected[data[i]] = true;
                }
            });
        },
        initSimpleSelect(data, type) {
            this.$nextTick(() => {
                if (data) {
                    this.selected[data] = true;
                }
                this.type = type;
            });
        },
        initDestination(destination) {
            this.$nextTick(() => {
                let destinationsSelected = destination;
                for (let i = 0; i < destinationsSelected.length; i++) {
                    this.selected[destinationsSelected[i]] = true;
                }
            });
        },
        initSelectedDate(date) {
            this.$nextTick(() => {
                let dateSelected = date;
                for (let i = 0; i < dateSelected.length; i++) {
                    this.selected['date_' + dateSelected[i]] = true;
                }
            });
        },
        initSelectedDuration(duration) {
            this.$nextTick(() => {
                let durationSelected = duration;
                for (let i = 0; i < durationSelected.length; i++) {
                    this.selected[durationSelected[i]] = true;
                }
            });
        },
        onClickParentDestination(
            parentDestinationCodeClicked, childDestinationCodes) {
            this.updated = true;
            if (this.selected[parentDestinationCodeClicked]) {
                this.selected[parentDestinationCodeClicked] = !this.selected[parentDestinationCodeClicked];
                childDestinationCodes.forEach((childDestinationCode) => {
                    if (childDestinationCode.charAt(0) ===
                        parentDestinationCodeClicked) {
                        this.selected[childDestinationCode] = !this.selected[childDestinationCode];
                    }
                });
            }
            else {
                this.selected[parentDestinationCodeClicked] = true;
                childDestinationCodes.forEach((childDestinationCode) => {
                    if (childDestinationCode.charAt(0) ===
                        parentDestinationCodeClicked) {
                        this.selected[childDestinationCode] = true;
                    }
                });
            }
        },
        onClickChildDestination(childDestinationCodeClicked) {
            this.updated = true;
            if (this.selected[childDestinationCodeClicked]) {
                this.selected[childDestinationCodeClicked] = !this.selected[childDestinationCodeClicked];
            }
            else {
                this.selected[childDestinationCodeClicked] = true;
            }
        },
        onClickSelectedDate(selectedDate) {
            this.updated = true;
            if (this.selected['date_' + selectedDate]) {
                this.selected['date_' + selectedDate] = !this.selected['date_' +
                selectedDate];
            }
            else {
                this.selected['date_' + selectedDate] = true;
            }
        },
        onClickSelectedDuration(selectedDuration) {
            this.updated = true;
            if (this.selected['duration_' + selectedDuration]) {
                this.selected['duration_' + selectedDuration] = !this.selected['duration_' +
                selectedDuration];
            }
            else {
                this.selected['duration_' + selectedDuration] = true;
            }
        },
        onSelect(selectedElement) {
            this.updated = true;
            if (this.selected[selectedElement]) {
                this.selected[selectedElement] = !this.selected[selectedElement];
            }
            else {
                this.selected[selectedElement] = true;
            }
        },
        onSelectSimple(selectedElement) {
            this.updated = true;
            this.selected = {};
            this.selected[selectedElement] = true;
            this.$wire.updateSearch(this.type, this.selected)
            window.dispatchEvent(
                new CustomEvent('open-load'),
            );
            window.dispatchEvent(
                new CustomEvent('close-selector'),
            );
        }
    };
};

window.initCruisePage = function(country = '') {
    window.sliders.cruiseSliders = window.sliders.setupCruisePageSliders();

    const input = document.querySelector('input[type="tel"]');
    let option = {
        localizedCountries: intlTelInputFR,
        initialCountry: 'FR',
        preferredCountries: ['FR', 'MC', 'BE', 'CA', 'TN', 'MA', 'DZ'],
        separateDialCode: true,
        customContainer: 'input-tel-wrapper',
        dropdownContainer: document.body,
    };
    if (country !== '') {
        option = {
            localizedCountries: intlTelInputFR,
            initialCountry: country,
            preferredCountries: ['FR', 'MC', 'BE', 'CA', 'TN', 'MA', 'DZ'],
            separateDialCode: true,
            customContainer: 'input-tel-wrapper',
            dropdownContainer: document.body,
        };
    }
    window.intlTelInput = intlTelInput(input, option);
    window.sliders.productPage = window.sliders.setupProductPageSliders();
};

window.initOrderPage = function(country = '') {
    const input = document.querySelector('input[type="tel"]');
    let option = {
        localizedCountries: intlTelInputFR,
        initialCountry: 'FR',
        preferredCountries: ['FR', 'MC', 'BE', 'CA', 'TN', 'MA', 'DZ'],
        separateDialCode: true,
        customContainer: 'input-tel-wrapper',
        dropdownContainer: document.body,
    };
    if (country !== '') {
        option = {
            localizedCountries: intlTelInputFR,
            initialCountry: country,
            preferredCountries: ['FR', 'MC', 'BE', 'CA', 'TN', 'MA', 'DZ'],
            separateDialCode: true,
            customContainer: 'input-tel-wrapper',
            dropdownContainer: document.body,
        };
    }
    window.intlTelInput = intlTelInput(input, option);
    window.bookingValidator = new BookingValidator();

    window.sliders.setupOrderCarousel();
};

window.setCruisePageCarouselItem = function(itemIndex) {
    window.sliders.setCruisePageCarouselItem(itemIndex);
};

window.setCruisePageCarouselItemInThumbnails = function(itemIndex) {
    window.sliders.setCruisePageCarouselItemInThumbnails(itemIndex);
};

window.nextCruiseCarousel = function() {
    window.sliders.nextCruiseCarousel();
};

window.prevCruiseCarousel = function() {
    window.sliders.prevCruiseCarousel();
};

window.initHomePage = function() {
    window.sliders.homeSliders = window.sliders.setupHomeSliders();
    const form = document.getElementById('subscribe-newsletter');
    if (form !== null) {
        form.addEventListener('submit', function (e) {
            const email = document.getElementById('email').value;

            e.preventDefault();
            subscribeNewsletter(email, 'newsletter-info');
        });
    }
};

window.initAllReviewsPage = function() {
    window.sliders.reviewsPageSlider = window.sliders.setupReviewPageSlider();
};

function setupFilterTabBehavior() {

}

window.initSearchPage = function() {
    addBackToTop({
        backgroundColor: '#142E6C',
        textColor: '#fff',
    });
    window.sliders.setupSearchPageSliders();
    window.scSearchPageManager = scSearchPageManager;
    window.scSearchFilterManager = scSearchFilterManager;

    setupFilterTabBehavior();
};

window.submitOrder = function(payload) {
    return new Promise(function(resolve, reject) {
        axios.post('/api/order', payload).then((response) => {
            resolve(response);
        }).catch((e) => {
            reject(e);
        });
    });
};

window.initCompaniesPage = function() {
    sliders.setupCompaniesPageSlider();
};

const footerForm = document.getElementById('subscribe-newsletter-footer');

footerForm.addEventListener('submit', function(e) {
    const email = document.getElementById('email-footer').value;

    e.preventDefault();
    subscribeNewsletter(email, 'newsletter-info-footer');
});

function subscribeNewsletter(emailToSubscribe, elementId) {
    return axios.post('/subscribe-newsletter?email=' + emailToSubscribe).
        then((response) => {
            document.getElementById(
                elementId).innerHTML = 'Un email de confirmation vous à été envoyé';
            return response;
        }).
        catch((e) => {
            document.getElementById(
                elementId).innerHTML = 'Une erreur est survenue';
            return e.response;
        });
}

window.submitBook = function(reference, step, payload) {
    return axios.patch(`/api/order/${reference}/booking/${step}`, payload).
        then((response) => {
            return response.data;
        }).
        catch((e) => {
            return e.response;
        });
};

window.updateAllCabinShipCarousel = function() {
    sliders.updateAllCabinShipCarousel();
};

window.updateAllSliders = function() {
    sliders.updateAllSliders();
}

const btnTop = document.getElementById('top');
if (btnTop !== null) {
    btnTop.addEventListener('click', e => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
}

/**
 * set scroll top after clicking on product tabs
 * @type {NodeListOf<Element>}
 */
let scProductTab = document.querySelectorAll('.sc-productTab');
if (scProductTab !== null) {
    scProductTab.forEach(element => {
        element.addEventListener('click', (event) => {
            window.scrollTo(0, 600);
        });
    });
}

document.querySelector('.no-js')?.classList.remove('no-js');
