/**
 * Objet javascript pour gérer le filtre de recherche
 * @type {{}|{}}
 */
export let scSearchFilterManager = scSearchFilterManager || {} ;

scSearchFilterManager.idSearchFilterShip = document.getElementById('sc-searchFilterShip');
scSearchFilterManager.idSearchFilterCompany = document.getElementById("sc-searchFilter-companyError");
scSearchFilterManager.idSearchFilterOpenSelectorCompany = document.getElementById('sc-searchFilter-openSelectorCompany');
scSearchFilterManager.idSearchFilterComapnyButton = document.getElementById('sc-searchFilter-companyButton');
scSearchFilterManager.classSearchFilterCompanyList = document.getElementsByClassName('sc-searchFilter-companyList');

/**
 * Methode au clic sur un element de la company, on cache le message d'erreur affiché au niveau du select bateau
 */
scSearchFilterManager.companyInfo = function() {
    if (scSearchFilterManager.idSearchFilterCompany !== null) {
        scSearchFilterManager.idSearchFilterCompany.classList.add("sc-searchFilterCompanyError--display");
    }
};

/**
 * Methode qui verfifie si au moins une checkbox de company est cochée
 * Au clic sur le bouton bateau
 */
if (scSearchFilterManager.idSearchFilterShip !== null) {
    scSearchFilterManager.idSearchFilterShip.onclick = function () {
        let checkedCount = 0;
        //texte par defaut affiché dans le select 'Compagnie' quand aucune compagnie n'est selectionnée.
        let textContent = scSearchFilterManager.idSearchFilterOpenSelectorCompany !== null
                            ? scSearchFilterManager.idSearchFilterOpenSelectorCompany.textContent.trim()
                            : '';

        for (var i = 0; i < scSearchFilterManager.classSearchFilterCompanyList.length; i++) {
            if (scSearchFilterManager.classSearchFilterCompanyList[i].querySelector('input').checked) {
                checkedCount++;
            }
        }

        if (checkedCount === 0 && scSearchFilterManager.idSearchFilterCompany !== null) {
            scSearchFilterManager.idSearchFilterCompany.classList.remove("sc-searchFilterCompanyError--display");
        }

        /**
         * cas où le visiteur a selectionné une ou plusieurs compagnies mais n'a pas cliqué sur le bouton valider de compagnie
         */
        if (checkedCount > 0 && textContent === 'Compagnie') {
            scSearchFilterManager.idSearchFilterComapnyButton.click();
        }
    };
}
