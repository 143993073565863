import validator from "validator/es";

export default class BookingValidator {
    isValidEmail(input) {
        return validator.isEmail(input);
    }

    isValidString(input) {
        return input?.length > 0;
    }

    isValidPhoneNumber(input) {
        return this.isValidString(input);
    }

    isValidGender(input) {
        return validator.isIn(input, ['1', '2', '3']);
    }

    isValidDate(input) {
        return validator.isDate(input);
    }

    isValidCountry(input) {
        return validator.isISO31661Alpha2(input);
    }

    isValidInsurance(input) {
        return validator.isIn(input, ['AY2023023', 'AR2023050', 'NONE']);
    }
}
